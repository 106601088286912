<script>
    import {getDocumentacion} from "@/routes/api/views/flujo";
    import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
    import {required} from "@/commons/utils/Rules";
export default {
    name: 'PzBaseFormDocumentacion',
    comments: [],
        mixins: [baseFormFlujo],
    data() {
        return {
            reference: 'formulariodocumentacion',
            settings: {},
            formulariodocumentacion: {},
            rules_formulariodocumentacion:
                {
                    formulariodocumentacion:[required('')]
                },
        }
    },

    methods: {

        // eslint-disable-next-line no-unused-vars
        getRule(name) {
            return null;
            },
            getDocs(type) {
                this.$store.dispatch('loader/up',{trigger:this.$options.name})
                getDocumentacion({
                    id: this.producto.id,
                    type: type
                }).then((res) => {
                    this.docs = res.data.documentaciones;
                    this.formulariodocumentacion = res.data.documentaciones.form;
                    this.settings = res.data.documentaciones.settings;
                }).catch(() => {
                    this.docs = [];
                }).finally(() => {
                    this.$store.dispatch('loader/down',{trigger:this.$options.name})
                });
            },
    },
}
</script>
