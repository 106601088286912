<template>
  <pz-form-flujo
    v-model="descuentoTerceros['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.descuento_terceros_factura.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="descuentoTerceros"
      status-icon
      :rules="rules_descuentoTerceros"
    >
      <el-row :gutter="20">
        <el-col
          :span="24"
          class="text-center"
        >
          <el-form-item
            prop="cheque"
            :error="errors.get('cheque')"
          >
            <label>{{ $t('forms_flujo.descuento_terceros_factura.doc_label') }} - {{ $t('forms_flujo.descuento_terceros_factura.document') }}</label>
            <pz-upload
              v-model="descuentoTerceros.cheque"
              :tip="$t('forms_flujo.descuento_terceros_cheque.document_warning')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="documento_librador"
            :label="$t('forms_flujo.descuento_terceros_factura.librador_doc_number')"
            :error="errors.get('documento_librador')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.documento_librador"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_factura.librador_doc_number')"
              @change="errors.clear('documento_librador')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombre_librador"
            :label="$t('forms_flujo.descuento_terceros_factura.librador_name')"
            :error="errors.get('nombre_librador')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.nombre_librador"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_factura.librador_name')"
              @change="errors.clear('nombre_librador')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_contacto"
            :label="$t('forms_flujo.descuento_terceros_factura.librador_phone')"
            :error="errors.get('telefono_contacto')"
            class=""
          >
            <el-input
              v-model.number="descuentoTerceros.telefono_contacto"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_factura.librador_phone')"
              @change="errors.clear('telefono_contacto')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email"
            :label="$t('forms_flujo.descuento_terceros_factura.librador_email')"
            :error="errors.get('email')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.email"
              type="text"
              :placeholder="$t('forms_flujo.descuento_terceros_factura.librador_email')"
              @change="errors.clear('email')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>

<script>
    import 'element-ui/lib/theme-chalk/display.css'
    import baseFormDescuentosTerceros from "./baseFormDescuentosTerceros";
    import {required, Rut} from "@/commons/utils/Rules";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";

    export default {
        name: 'PzFacturaDescuentoTerceros',
        components: {PzFormFlujo},
        mixins: [baseFormDescuentosTerceros],
        data() {
            return {
                descuentoTerceros: {
                    documento_librador: '',
                    nombre_librador: '',
                    telefono_contacto: '',
                    email: '',
                },
                disable: true,
                rules_descuentoTerceros:
                    {
                        documento_librador: [Rut('')],
                        nombre_librador: [required('')],
                    },
            };
        },

        created() {
        },
        mounted() {
        },
        methods: {}
    }
</script>

