<template>
  <comment
    :is="frame"
    :route="route"
  />
</template>

<script>

import {isEmpty, isMobile} from '@/commons/utils/functions';
import PzLoader from "@/commons/PzLoaderMixins";
import {getDocsSign} from "@/routes/api/views/vales";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";

export default {
    name: 'PzFirmaDocs',
    mixins: [PzLoader,  baseFormFlujo],
    provide: function () {
        return {
            myForm: this
        }
    },
    data() {
        return {
            route: null,
            formulario: {},
        }
    },
    computed: {

        frame() {
            return 'pz-naat' ;
        }
    },

    created() {
        this.$store.dispatch('loader/up', {trigger: this.$options.name})

        var id = this.loan;
        if(this.$route.params.loan)
            id = this.$route.params.loan;

        if(!isMobile()) {
            this.route = location.href + '/' + id;
            return true;
        }


        getDocsSign({'loan':id}).
        then((res) => {
            if (res.data.valid && !isEmpty(res.data.ruta)) {
                this.route = res.data.ruta;
            } else {
                let message = isEmpty(res.data.ruta) && res.data.valid
                    ? this.$t('views.documentacion.vale.already_signed')
                    : this.$t('views.documentacion.vale.not_route_obtain_naat');
                this.$emit('nextStep');
                this.$store.dispatch('app/setMessage', message);
            }
        }).
        catch((error) => {
            this.$store.dispatch('app/catchErrors', {_this: this, error: error});
        }).
        finally(() => {
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        });
    },
    methods: {
    },
}
</script>

